import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AgoraRTC from "agora-rtc-sdk-ng";

import {
  LocalUser,
  RemoteUser,
  useJoin,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  usePublish,
  useRemoteAudioTracks,
  useRemoteUsers,
} from "agora-rtc-react";
import ScreenShare from "./ShareIng";

export const LiveVideo = () => {
  const appId = "6857853f99024834a4b38c48aae72c28";
  const { channelName } = useParams();
  const [activeConnection, setActiveConnection] = useState(true);
  const [micOn, setMic] = useState(true);
  const [cameraOn, setCamera] = useState(true);
  const [screenSharing, setScreenSharing] = useState(false);
  const [token, setToken] = useState(null);
  const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
  const { localCameraTrack } = useLocalCameraTrack(cameraOn);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await fetch(
          "https://ottadmin.imboxocinema.com/api/agora-token",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ channelName, userAccount: 129 }),
          }
        );
        const data = await response.json();
        setToken(data.data.token);
      } catch (error) {
        console.error("Failed to fetch token:", error);
      }
    };

    fetchToken();
  }, [channelName]);

  useJoin(
    {
      appid: appId,
      channel: channelName,
      token: token,
    },
    activeConnection
  );

  usePublish([localMicrophoneTrack, localCameraTrack]);

  const remoteUsers = useRemoteUsers();
  const { audioTracks } = useRemoteAudioTracks(remoteUsers);

  audioTracks.forEach((track) => track.play());

  return (
    <>
      <div id="remoteVideoGrid">
        {remoteUsers.map((user) => (
          <div key={user.uid} className="remote-video-container">
            <RemoteUser user={user} />
          </div>
        ))}
      </div>
      <div id="localVideo" style={{ width: "400px", height: "300px" }}>
        {/* Display local user */}
        <LocalUser
          audioTrack={localMicrophoneTrack}
          videoTrack={localCameraTrack}
          cameraOn={cameraOn && !screenSharing} // Ensure camera video shows when not screen sharing
          micOn={micOn}
          playAudio={false}
          playVideo={cameraOn && !screenSharing}
          className=""
        />
        <div>
          <div id="controlsToolbar">
            <div id="mediaControls">
              <button className="btn" onClick={() => setMic((a) => !a)}>
                Mic
              </button>
              <button className="btn" onClick={() => setCamera((a) => !a)}>
                Camera
              </button>

              <button>
                <ScreenShare appId={appId} channelName={channelName} />
              </button>

              {/* <button
                className="btn"
                onClick={() =>
                  screenSharing ? stopScreenShare() : startScreenShare()
                }
              >
                {screenSharing ? "Stop Screen Share" : "Start Screen Share"}
              </button> */}
            </div>
            <button
              id="endConnection"
              onClick={() => {
                setActiveConnection(false);
                navigate("/");
              }}
            >
              {" "}
              Disconnect
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
