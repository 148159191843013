import React, { useState, useRef, useEffect } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";

const ScreenShare = ({ channelName, appId }) => {
  const client = useRef(null);
  const localScreenTrack = useRef(null);
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);

  const [joined, setJoined] = useState(false);
  const [isSharingScreen, setIsSharingScreen] = useState(false);

  useEffect(() => {
    // Initialize Agora client
    client.current = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

    const getCameraAccess = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (remoteVideoRef.current) {
          remoteVideoRef.current.srcObject = stream; // Set the video stream to the video element
        }
      } catch (err) {
        console.error("Error accessing the camera: ", err);
      }
    };

    getCameraAccess();

    return () => {
      // Cleanup: stop all tracks when the component unmounts
      if (remoteVideoRef.current && remoteVideoRef.current.srcObject) {
        const tracks = remoteVideoRef.current.srcObject.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, []);

  // Join the channel and start screen sharing
  const startScreenShare = async () => {
    if (!client.current) return;

    try {
      // Join a channel
      await client.current.join(appId, channelName, null, null);

      // Create a local screen video track
      const screenTrack = await AgoraRTC.createScreenVideoTrack();

      localScreenTrack.current = screenTrack;

      // Display the screen share locally
      localScreenTrack.current.play(localVideoRef.current);

      // Publish the screen track to the remote users
      await client.current.publish([localScreenTrack.current]);

      setJoined(true);
      setIsSharingScreen(true);

      // Subscribe to remote users joining the channel
      client.current.on("user-published", async (user, mediaType) => {
        await client.current.subscribe(user, mediaType);
        if (mediaType === "video") {
          const remoteTrack = user.videoTrack;
          remoteTrack.play(remoteVideoRef.current); // Show the remote user's screen
        }
      });
    } catch (error) {
      console.error("Error during screen share:", error);
    }
  };

  const stopScreenShare = async () => {
    if (localScreenTrack.current) {
      localScreenTrack.current.stop(); // Stop local screen share
      localScreenTrack.current.close(); // Close track to clean resources
      await client.current.unpublish(localScreenTrack.current);
      setIsSharingScreen(false);
    }
  };

  return (
    <div>
      <div style={{ display: "none" }}>
        <video
          ref={localVideoRef}
          style={{ width: "300px", height: "200px", border: "1px solid black" }}
          muted
          autoPlay
        ></video>
        <video
          ref={remoteVideoRef}
          style={{ width: "300px", height: "200px", border: "1px solid black" }}
          autoPlay
        ></video>
      </div>
      {!joined && (
        <button onClick={startScreenShare}>Start Screen Share</button>
      )}
      {isSharingScreen && (
        <button onClick={stopScreenShare}>Stop Screen Share</button>
      )}
    </div>
  );
};

export default ScreenShare;
